.App {
  text-align: center;
}

header {
  background-color: #041a57;
  position: sticky;
  top: 0;
  z-index: 1000;
  max-height: 15vh;
  display: flex;
  flex-direction: row;
  padding: 3vh;
  justify-content: space-between; 
  align-items: center;
  font-size: 2rem;
  color: rgb(232, 230, 227);
  box-shadow: 0rem 0.12rem 0.5rem black;

  img{
    width: 5vh;
    margin: 1.3vh;
    
  }

  h2{
    color :rgb(232, 230, 227);
  }

  a{
    text-decoration: none;
  }
}

.social-links,
nav,
.translate-section {
  display: flex;
  align-items: center;
}

.social-links{
  margin-right: auto;
  align-items: center;
  a{
    height: fit-content;
    width: fit-content;
    align-self: center;
    align-content: center;
  }
  img{
    align-self: center;
    justify-self: center;
  }
  
}

.header-titles{
  flex-grow: 1; 
  text-align: left;
  h2{
    margin: 0;
    margin-left: 8vh;
    font-size: 3.4rem;
  }
}

nav{
  display: flex;
  margin-left: auto;
  flex-direction: row;
  width: 32vh;
  margin-right: 3vh;
  li{
    margin-left: 2vh;    
    list-style-type: none;
    width: fit-content;
    min-width: 10vh;  
    white-space: nowrap  
  }
  a{
    color: rgb(232, 230, 227);
    
  }
  a:hover{
    color: #287ef0;
  }
}

.translate-section{
  display: flex;
  margin-left: auto;
  flex-direction: row;
  button{
    display: flex;
    height: 5vh;
    width: 5vh;
    margin-left: 1.5vh;
    align-items: center; 
    justify-content: left; 
    padding: 0;
    padding-right: 2vh;    
    background-color:  rgb(232, 230, 227);
    color:  #041a57;
    font-weight: bold;
    border-radius: 7vh; 
    overflow: hidden;
    cursor: pointer;
    transition: width 0.3s ease; 
    img{
      width: 5vh;
      height: 5vh;
      object-fit: cover;
      margin: 0;
      margin-right: 1vh;
    }
  }
  button:hover{
    width: 10vh;
  }
  button.selected {
    border: 2px solid  #28c1f0;
    
  }
}

.burger-menu {
  display: none;
  background: none;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  color: rgb(232, 230, 227);
  margin-top: 2vh;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 12vh; /* Ajuste selon la hauteur de ton header */
  right: 0;
  background-color: #041a57;
  padding: 1rem;
}

h1{
  font-size: 3.8rem;
  margin-top: 0;
  margin-left: 15vh;
  margin-bottom: 9vh;
  color: rgb(232, 230, 227);
}

h2{
  font-size: 2rem;
  line-height: 1;
  margin-left: 25vh;
  color: #28c1f0;
}

.welcome{
  background-color: #0a2f97;
  padding-top: 8vh;
  padding-bottom: 2vh;
}

.welcome-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  h2{
    margin: 0;
  }
  h3{
    margin: 0;
    color:  #28c1f0;
  }
}

.welcome-section{
  display: flex;
  flex-direction: column;
  width: 50vh;
  margin-left: 3vh;
  margin-right: 3vh;
  color: rgb(232, 230, 227);
  
}

.welcome-section.part2{
  .block{
    margin-bottom: 2.5vh;
  }
  p{
    margin-bottom: 0;
  }  
}

.filter-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  label{
    color: rgb(232, 230, 227);
    font-size: 1.6rem;
    margin-right: 1vh;
    margin-left: 3vh;
  }
  select{
    width: 13vh;
    height: 3vh;
  }
}

.project-list{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 18vh;
  padding-right: 18vh;
  min-height: 71vh;
}

.card-container{
  width: 40vh;
  height: 68vh;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2vh;
  background-color: #041a57;
  color: rgb(232, 230, 227);
  .image-container{
    width: 40vh;
    height: 40vh;
    overflow: hidden;
  }
  .card-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: white;
    transition: transform 0.3s;
  }
  .card-image:hover{
    transform: scale(1.1);
  }
  .card-title{
    font-size: 2rem;
    color: #4995fa;
  }
  .card-type{
    font-weight: bold;
  }
  .card-description{
    height: 18vh;
  }

  .more-infos{
    text-decoration: none;
    padding: 0.5vh;
    color: rgb(232, 230, 227);
    font-weight: bold;
    background-color: #4995fa;
    border: 2px solid rgb(232, 230, 227);
  }

  a:hover{
   color:#041a57 ; 
  }
}

.capsule{
  background-color: #0a2f97;
  padding-bottom: 2.5vh;

  .img-solo{
    display: flex;
    align-self: center;
    justify-content: center;
    width: 90vh; 
    height: 60vh; 
    margin-top: 2vh;
    object-fit: contain;
    background-color: white;
  }  
  video{
    height: 30vh;
    width: 53vh;
  }
  iframe{
    height: 30vh;
    width: 53vh;
  }
}

.media-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  

  h2{
    margin-left:0 ;
  }
  .link-container{
    display: flex;
    flex-direction: row;
    
    a{
      margin: 3vh;
    }
    .link{
      text-decoration: underline;
      color: rgb(232, 230, 227);
      font-size: 1.5rem;
    }

    .link:hover{
      color:  #28c1f0;
    }
  }
}

.project-content{
  display: flex;
  flex-direction: column;
  color: rgb(232, 230, 227);
  align-items: center;

  img{
    width: 50%;
    margin: 2.5vh;
    max-height: 50vh;
    object-fit: contain;
  }
  video{
    width: fit-content;
    margin: 2.5vh;
    max-height: 50vh;
    object-fit: contain;
  }
  p{
    width: 50%;
    font-size: 1.2rem;
  }
}

.carrousel {
  display: flex;
  align-self: center;
  position: relative;
  justify-content: center; 
  width: 90vh;  /* Largeur fixe du carrousel */
  height: 60vh;  /* Hauteur fixe du carrousel */
  margin-top: 2vh;
  overflow: hidden; /* S'assure que rien ne déborde */

  #buttonPrev, #buttonNext {
    width: 3rem;
    height: 3rem; 
    position: absolute;
    top: 45%; 
   
    padding: 1rem;
    border-radius: 50%;
    cursor: pointer;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center; 
  }

  #buttonPrev {
    left: 5%; 
    transform: rotate(180deg) ; 
  }

  #buttonNext {
    right: 1vh;
    left: 85%; 
  }

  div {
    height: 100%; /* Correspond à la hauteur du carrousel */
    overflow: hidden;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  img {
    align-self: center;
    height: 100%;
    width: auto; 
  }

  span {
    position: absolute;
    align-self: flex-end;
    bottom: 1rem; /* Place l'élément en bas du carrousel */
    padding: 0.5rem;
    color: white;
    background-color: black;
    font-size: 1.2rem;
  }
}

.download-button{
  display: flex;
  width: 18vh;
  padding: 0.5vh;
  margin-top: 2vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  background-color: #041a57;
  color: rgb(232, 230, 227);
  border: 3px solid rgb(232, 230, 227);
}

.download-button:hover{
  background-color: #448aff;
  color: rgb(232, 230, 227);
}

.about-container1{
  background-color: #0a2f97;
  padding-bottom: 2vh;
}

.about-container2{
  background-color: #0d3ab6;
  padding-top: 3vh;
  padding-bottom: 2vh;
  h2{
    margin-top: 0;
  }
}

.about-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 25vh;
  padding-right: 25vh;
  color: rgb(232, 230, 227);
}

.about-section.part1{
align-items: center;
  p{
    display: flex;
    width: 80%;
    justify-content: center;
    margin-bottom: 4vh;
  }
}

.skills-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.skill-card{
  display: flex;
  flex-direction: column;
  width: 30vh;
  padding: 1.5vh;
  margin-top: 1.5vh;
  .skill-title{
    align-self: center;
    font-size: 2rem;
    margin-bottom: 1vh;
  }
}

.about-section.part3{
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.education-card{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 30vh;
  margin-top: 2.5vh;
  .formation-name{
    font-size: 1.3rem;
    align-self: center;
    font-weight: bold;
  }
  .school-name{
    font-size: 1.1rem;
  }
  .dates{
    font-weight: bold;
  }
}

.folding-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
}
.folding-menu .menu-header {
  display: flex;
  justify-content: space-between;
  width: 96%;
  align-items: center;
  background: #041a57;
  padding: 0.4vh;
  border-radius: 0.3rem;
}
.folding-menu .menu-header span {
  color: rgb(232, 230, 227);
  font-size: 1.1rem;
  padding-left: 0.5rem;
  font-weight: bold;
  cursor: pointer;
}
.folding-menu .menu-header img {
  max-width: 2.5vh;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.folding-menu .menu-content {
  width: 96%;
  transform: translateY(-100%); /* Déplace le contenu vers le haut pour le cacher initialement */
  
  padding-bottom: 0.2vh;
  z-index: -10;
  height: 0;
  overflow: hidden;
  background-color:#3f51b5;
  border-radius: 0 0 0.3rem 0.3rem;
}
.folding-menu .menu-content p {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  padding: 0.4vh;
  color: rgb(232, 230, 227);
}
.folding-menu.expanded .menu-content {
  height: 100%;
  transform: translateY(0);
  z-index: 1;
}
.folding-menu.expanded .menu-header img {
  transform: rotate(90deg);
}

.legal-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 35vh;
  padding-right: 35vh;
 
  p{
    color: rgb(232, 230, 227);
    margin: 1vh;
  }
}

footer{
  margin-top: 5vh;
  margin-bottom: 0;
  height: fit-content;
  padding-top: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(27, 29, 30);
  color: white;
  a{
    text-decoration: none;
    margin-bottom: 1.5vh;
    color :white;
  }
  span{
    font-size: 1.7rem;
    color: #28c1f0;
  }
  .contact-form{
    display: flex;
    flex-direction: column;
    width: 40%;
    form{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: inherit;
      label{
        margin: 1.5vh;
      }
      input{
        width: 60%;
      }
      textarea{
          width: 100%;
          height: 8vh;
      }
      button{
        align-self: center;
        width: 15vh;
        height: 4vh;
        margin-top: 1vh;
        font-weight: bold;
      }
    }
  }
}

/* Tablettes */
@media only screen and (min-width: 768px) and (max-width: 1100px) {

  h1{
    font-size: 3rem;
    margin-left: 1vh;
    margin-bottom: 2.5vh;
  }
  .header-titles{
    flex-grow: 0;
    width: auto;

    h2{
      font-size: 2rem;
      margin-left: 2vh;
      
    }
  }

  .social-links,.translate-section {
    display: none;
  }
  
  .burger-menu {
    display: block;
    margin-top: 0.9vh;
  }
  
  .mobile-menu.open {
    display: block;

      .social-links,.translate-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        
      }
      .social-links{
        img{
          margin: 0;
          margin-bottom: 1vh;
        }
      }
      .translate-section{
        button{
          margin-left: 0;
          margin-bottom: 1vh;
        }
      }   
  }

  h2{
    margin-left: 10vh;
  }
  .about-section,.legal-content{
    padding-left: 3vh;
    padding-right: 3vh;
  }

  .carrousel{
    width: 100%;
  }

  .capsule{
    .img-solo{
      height: 30vh;
      width: fit-content;
    }
  }

  .project-content{
    p{
      width: 80%;
    }
    img{
      width: 85%;
    }
  }

  footer{
    .contact-form{
      width: 60%;
    }
  }
}

/* Mobiles */
@media only screen and (max-width: 767px) {

  h1{
    font-size: 3rem;
    margin-left: 1vh;
    margin-bottom: 2.5vh;
  }
  .header-titles{
    flex-grow: 0;
    width: auto;

    h2{
      display: none;
    }
  }

  .social-links, .translate-section{
    flex-direction: column;
  }

  .social-links{
    img{
      margin: 0;
    }
  }

  nav{
    margin-left: 0;
    li{
      margin-top: 1.5vh;      
    }
    .nav-link{
      margin-left: 1vh;
    }
  }

  .translate-section{
    button{
      margin-left: 0;
      margin-top: 1.5vh;
    }
  }

.social-links,.translate-section {
  display: none;
}

.burger-menu {
  display: block;
}

.mobile-menu.open {
  display: block;
  top: 13vh;
    .social-links,.translate-section{
      display: flex;
      flex-direction: column;
      align-items: center;
      
    }
    .social-links{
      img{
        margin: 0;
        margin-bottom: 1vh;
      }
    }
    .translate-section{
      button{
        margin-left: 0;
        margin-bottom: 1vh;
      }
    }   
}



  h2{
    margin-left: 2vh;
  }
  
  .about-section,.legal-content{
    padding-left: 1vh;
    padding-right: 1vh;
  }

  .filter-container{
    flex-direction: column;

    label{
      margin: 0;
      margin-bottom: 0.5vh;
    }
  }

  .media-container{
    .link-container{
      flex-direction: column;
      .download-button{
        margin: 1.5vh;
      }
    }
  }

  .carrousel{
    width: 100%;
    height: 35vh;
    #buttonPrev, #buttonNext{
      width: 1.5vh;
      height: 1.5vh;
    }
    
  }

  .capsule{

    iframe{
      width: 38vh;
    }
    video{
      width: 42vh;
      max-height: 30vh;
    }
    .img-solo{
      width: 38vh;
      height: fit-content;
    }
  }

  
 
  .project-content{
    padding-left: 1vh;
    padding-right: 1vh;
    p{
      width: 100%;

      
    }
    img{
      width: 95%;
    }
    video{
      width: 42vh;
      max-height: 30vh;
    }
  }
  footer{
    .contact-form{
      width: 85%;
    }
  }
  
}
